import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import HowToUse from '../../components/HowToUse';
import { genHowToUseBreadcrumbJsonLd } from '../../utils/jsonld';

const meta = {
  en: {
    title: 'Three Steps to Get Started - EverTool',
    subtitle: 'Three steps to start to write Markdown, code highlight in Evernote',
  },
  'zh-tw': {
    title: '三步驟開始使用 - EverTool',
    subtitle: '三步驟讓你開始在 Evernote 使用 Markdown, code highlight 等功能',
  },
  'zh-cn': {
    title: '三步骤开始使用 - EverTool',
    subtitle: '三步骤让你开始在印象笔记使用 Markdown, code highlight 等功能',
  },
  ja: {
    title: '3つのステップが開始を取得します - EverTool',
    subtitle: 'マークダウンを書くために開始するには、3つのステップ、Evernoteの中のコードのハイライト',
  },
};

class ThreeStepsToGetStartedPage extends React.Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const config = {
      images: {
        mac: {
          intro1: [data.mac_intro1],
          intro2: [data.mac_intro2],
          intro3: [data.mac_intro3, data.mac_intro4],
        },
        win: {
          intro1: [data.win_intro1],
          intro2: [data.win_intro2],
          intro3: [data.win_intro3, data.win_intro4],
        },
      },
      introId: {
        title: 'intro.title',
        step1: 'intro.step1',
        step2: 'intro.step2',
        step3: 'intro.step3',
      },
    };

    this.state = {
      config,
    };
  }

  render() {
    const { pageContext } = this.props;
    const { config } = this.state;
    const { locale, url } = pageContext;

    return (
      <Layout
        pageContext={pageContext}
        seo={{
          title: meta[locale].title,
          subtitle: meta[locale].subtitle,
          hreflangs: pageContext.hreflangs,
          url,
          locale,
          jsonld: genHowToUseBreadcrumbJsonLd(meta[locale].title, url, locale),
        }}
      >
        <div className="how-to-wrapper">
          <HowToUse
            locale={locale}
            pageContext={pageContext}
            url={url}
            config={config}
          />
        </div>
      </Layout>
    );
  }
}

export default ThreeStepsToGetStartedPage;

export const pageQuery = graphql`
  query HowToThreeStepsQuery {
    mac_intro1: file(relativePath: { eq: "how-to/three-steps/mac/intro1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_intro2: file(relativePath: { eq: "how-to/three-steps/mac/intro2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_intro3: file(relativePath: { eq: "how-to/three-steps/mac/intro3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mac_intro4: file(relativePath: { eq: "how-to/three-steps/mac/intro4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_intro1: file(relativePath: { eq: "how-to/three-steps/win/intro1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_intro2: file(relativePath: { eq: "how-to/three-steps/win/intro2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_intro3: file(relativePath: { eq: "how-to/three-steps/win/intro3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    win_intro4: file(relativePath: { eq: "how-to/three-steps/win/intro4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
